import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  Collection,
  CollectionItem,
  createResourceEmbeddedCollection,
  GetCollectionResponse,
  preparePermissions,
} from 'src/app/access-rights/permissions.utils';
import { EmptyPermissions } from 'src/app/access-rights/permissions/permission-models';
import { DeviceModelReference } from 'src/app/device/device-shared/model/device.model';
import { handleError } from 'src/app/shared/util/http-utils';
import { environment } from 'src/environments/environment';
import { ProductInformationFile } from '../product-information-shared/model/product-information-file.model';
import { FilterCriteria } from '../product-information-shared/model/product-information-filter-criteria.model';
import { SoftwareOptionsRequest } from '../service/product-information.service';
import { SharedDeviceVendor } from 'src/app/core/model/device-vendor.model';

const ENDPOINT = `${environment.backendApi.shared.uri}/product-information/files`;
const FAVORITES_ENDPOINT = `${environment.backendApi.shared.uri}/product-information/favorites`;

export interface ProductInformationFileCollectionPermissions extends EmptyPermissions {}
export interface ProductInformationFilePermissions extends EmptyPermissions {}

export type ProductInformationFileCollectionResult = Collection<
  ProductInformationFile,
  ProductInformationFileCollectionPermissions,
  ProductInformationFilePermissions
>;
export type ProductInformationFileResult = CollectionItem<ProductInformationFile, ProductInformationFilePermissions>;

const qatmUpdateSoftwareUrl = `${environment.backendApi.qatm.uri}/devices`;

@Injectable({
  providedIn: 'root',
})
export class ProductInformationFileApiService {
  constructor(private http: HttpClient, private ActivatedRoute: ActivatedRoute) {}

  getFiles(
    deviceModelReference: DeviceModelReference,
    filterCriteria?: FilterCriteria,
    page?: number,
    pageSize?: number
  ): Observable<ProductInformationFileCollectionResult> {
    let params = new HttpParams();
    params = params.set('deviceVendor', deviceModelReference.vendor);
    params = params.set('deviceModelId', deviceModelReference.id);

    if (deviceModelReference.version) {
      params = params.set('deviceModelVersion', deviceModelReference.version);
    }

    if (filterCriteria) {
      filterCriteria.fileLanguages.forEach(language => (params = params.append('languages', language)));

      filterCriteria.fileTypes.forEach(type => (params = params.append('types', type)));
      if (page != null && pageSize != null) {
        params = params.set('page', page);
        params = params.set('pageSize', pageSize);
      }

      if (filterCriteria.nameFilter) {
        params = params.set('nameFilter', filterCriteria.nameFilter);
      }

      if ('favorites' in filterCriteria) {
        params = params.set('favorites', filterCriteria.favorites!);
      }
    }

    return this.fetchFile(params);
  }

  private fetchFile(params: HttpParams): Observable<ProductInformationFileCollectionResult> {
    return this.http.get<GetCollectionResponse<ProductInformationFile>>(ENDPOINT, { params: params }).pipe(
      map(response => {
        return {
          collection: createResourceEmbeddedCollection<ProductInformationFile, ProductInformationFilePermissions>(
            response?._embedded
          ),
          permissions: preparePermissions<ProductInformationFileCollectionResult>(response._links),
        };
      }),

      catchError(handleError)
    );
  }

  getTypesAndLanguages(deviceModelReference: DeviceModelReference): Observable<any> {
    let params = new HttpParams();
    params = params.set('deviceVendor', deviceModelReference.vendor);
    params = params.set('deviceModelId', deviceModelReference.id);

    if (deviceModelReference.version) {
      params = params.set('deviceModelVersion', deviceModelReference.version);
    }

    return this.http.get<any>(`${ENDPOINT}/languages`, { params: params });
  }

  getFileDownloadUri(id: string): Observable<string | undefined> {
    return this.http
      .get<{ data: { downloadUri: string } }>(`${ENDPOINT}/${id}/download-uri`)
      .pipe(map(response => response.data.downloadUri));
  }

  updateSoftware(deviceId: any, params: any): Observable<any> {
    return this.http.post(`${qatmUpdateSoftwareUrl}/${deviceId}/software`, params);
  }

  getRegistrationSoftware(body: SoftwareOptionsRequest): Observable<ProductInformationFileCollectionResult> {
    const endpoint =
      body.vendor === SharedDeviceVendor.QATM
        ? `${environment.backendApi.qatm.uri}/devices/registrations/software/update`
        : `${environment.backendApi.eltra.uri}/device/registrations/software/update`;

    return this.http
      .get<GetCollectionResponse<ProductInformationFile>>(`${endpoint}?serialNumber=${body.serialNumber}`)
      .pipe(
        map(response => {
          return {
            collection: createResourceEmbeddedCollection<ProductInformationFile, ProductInformationFilePermissions>(
              response?._embedded
            ),
            permissions: preparePermissions<ProductInformationFileCollectionResult>(response._links),
          };
        }),

        catchError(handleError)
      );
  }

  addFavorites(favoriteIds: string[]): Observable<any> {
    return this.http.post<any>(FAVORITES_ENDPOINT, favoriteIds);
  }

  removeFavorites(favoriteIds: string[]): Observable<string> {
    return this.http.delete(FAVORITES_ENDPOINT, { responseType: 'text', body: favoriteIds });
  }
}
