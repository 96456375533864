import { Injectable, signal } from '@angular/core';
import { Observable, switchMap, of, tap, map } from 'rxjs';
import { ProductInformationFileApiService } from '../api/product-information-files-api.service';
import { ProductInformationFileCollectionResult } from '../api/product-information-files-api.service';
import { DeviceModelReference } from 'src/app/device/device-shared/model/device.model';
import { FilterCriteria } from '../product-information-shared/model/product-information-filter-criteria.model';
import {
  ProductInformationFileType,
} from '../product-information-shared/model/product-information-file.model';

export type SoftwareOption = {
  id: string;
  name: string;
  description: string;
  type: ProductInformationFileType;
};

export type SoftwareOptionsRequest = {
  vendor?: string;
  serialNumber?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ProductInformationService {
  public page: number = 0;
  public pageSize: number = 10;
  constructor(private apiService: ProductInformationFileApiService) {}

  #files = signal<ProductInformationFileCollectionResult | undefined>(undefined);
  #languages = signal<string[] | undefined>(undefined);
  #types = signal<ProductInformationFileType[] | undefined>(undefined);
  #softwareOptions = signal<SoftwareOption[] | undefined>(undefined);

  softwareOptions = this.#softwareOptions.asReadonly();
  files = this.#files.asReadonly();
  languages = this.#languages.asReadonly();
  types = this.#types.asReadonly();

  getAll(
    deviceModelReference: DeviceModelReference,
    filterCriteria?: FilterCriteria,
    page?: number,
    pageSize?: number
  ): Observable<void> {
    return this.apiService
      .getFiles(deviceModelReference, filterCriteria, page, pageSize)
      .pipe(switchMap(files => of(this.#files.set(files))));
  }

  getTypesAndLanguages(deviceModelReference: DeviceModelReference): Observable<null> {
    return this.apiService.getTypesAndLanguages(deviceModelReference).pipe(
      switchMap(response => {
        return of(null).pipe(
          tap(() => {
            this.#languages.set(response.languages);
            this.#types.set(response.types);
          })
        );
      })
    );
  }

  getUri(fileId: string): Observable<string | undefined> {
    return this.apiService.getFileDownloadUri(fileId);
  }

  updateSoftware(deviceId: string, params: any): Observable<string | undefined> {
    return this.apiService.updateSoftware(deviceId, params);
  }

  getRegistrationSoftware(body: SoftwareOptionsRequest): Observable<void> {
    if (!body.vendor || !body.serialNumber) return of(undefined).pipe(tap(() => this.#softwareOptions.set(undefined)));
    this.#softwareOptions.set(undefined);
    return this.apiService.getRegistrationSoftware(body).pipe(
      map(response => {
        if (!response) return;
        const softwareOptions = response.collection.map(softwareOption => {
          return {
            id: softwareOption.data.id,
            name: softwareOption.data.name,
            description: softwareOption.data.description,
            type: ProductInformationFileType.Software,
          };
        });
        this.#softwareOptions.set(softwareOptions);
      })
    );
  }

  addFavorites(favoriteIds: string[]): Observable<any> {
    return this.apiService.addFavorites(favoriteIds);
  }

  removeFavorites(favoriteIds: string[]): Observable<string> {
    return this.apiService.removeFavorites(favoriteIds);
  }
}
