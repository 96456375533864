import { ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscriptions } from '../../util/Subscriptions';
import { Action } from '../card-header/card-header';
import { LongRunningTask } from '../button/button.component';
import { ConfirmDialogType } from '../confirm-dialog/model/confirmation-dialog-types';

export interface Step {
  actions: Action[];
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnDestroy {
  private subscriptions = new Subscriptions();

  @Input() currentPage: number = 0;

  @Input() title!: string;
  @Input() descriptiveSubtitle!: string;

  @Input() steps: Step[] = [];

  @Input() contentTemplate!: TemplateRef<any> | TemplateRef<any>[];
  @Input() subheadingTemplate?: TemplateRef<any>;
  @Input() type: ConfirmDialogType = ConfirmDialogType.Info;

  constructor(public activeModal: NgbActiveModal) {}

  close(): void {
    this.activeModal.close(false);
  }

  get currentStep(): Step {
    return this.steps[this.currentPage];
  }

  get currentStepTemplate(): TemplateRef<any> | undefined {
    if (!this.contentTemplate) return;
    if (Array.isArray(this.contentTemplate)) {
      return this.contentTemplate[this.currentPage];
    }
    return this.contentTemplate;
  }

  noopHandler: LongRunningTask = async () => {};

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribeAll();
  }
}
