import { Pipe, PipeTransform } from '@angular/core';

export let deviceModelDict: Record<string, string> = {
  ONHp2: 'ELEMENTRAC® ONH-p 2',
  CSd: 'ELEMENTRAC® CS-d',
  'CAMSIZER-P4': 'CAMSIZER® P4',
  'CAMSIZER-P5': 'CAMSIZER® P5',
  'CAMSIZER-3D': 'CAMSIZER® 3D',
  'CAMSIZER-X2': 'CAMSIZER® X2',
  'CAMSIZER-X3': 'CAMSIZER® X3',
  'CAMSIZER-M1': 'CAMSIZER® M1',
  'NANOTRAC-FLEX': 'NANOTRAC FLEX',
  'NANOTRAC-WAVE': 'NANOTRAC WAVE',
  BB50: 'BB 50',
  BB100: 'BB 100',
  BB200: 'BB 200',
  BB250: 'BB 250',
  BB300: 'BB 300',
  BB400: 'BB 400',
  BB500: 'BB 500',
  BB600: 'BB 600',
  ZM300: 'ZM 300',
  Twister: 'Twister',
  SR300: 'SR 300',
  SK300: 'SK 300',
  HM200: 'HM 200',
  SM100: 'SM 100',
  SM200: 'SM 200',
  SM300: 'SM 300',
  SM400: 'SM 400',
  GM200: 'GM 200',
  GM300: 'GM 300',
  RM200: 'RM 200',
  RS200: 'RS 200',
  RS300: 'RS 300',
  DM200: 'DM 200',
  DM400: 'DM 400',
  PM100: 'PM 100',
  PM100CM: 'PM 100 CM',
  PM200: 'PM 200',
  PM400: 'PM 400',
  MM200: 'MM 200',
  MM400: 'MM 400',
  Cryomill: 'Cryomill',
  MM500nano: 'MM 500 nano',
  MM500vario: 'MM 500 vario',
  MM500control: 'MM 500 control',
  Emax: 'Emax',
  XRDMillMcCrone: 'XRD-Mill McCrone',
  TM300: 'TM 300',
  TM500: 'TM 500',
  AS200basic: 'AS 200 basic',
  AS200digitcA: 'AS 200 Digit cA',
  AS200control: 'AS 200 control',
  AS300control: 'AS 300 control',
  AS400control: 'AS 400 control',
  AS450control: 'AS 450 control',
  AS450basic: 'AS 450 basic',
  AS200jet: 'AS 200 jet',
  AS200tap: 'AS 200 tap',
  PT100: 'PT 100',
  PT200: 'PT 200',
  PT300: 'PT 300',
  PT600: 'PT 600',
  PP25: 'PP 25',
  PP35: 'PP 35',
  PP40: 'PP 40',
  BT100: 'BT 100',
  TG200: 'TG 200',
  DR100: 'DR 100',
  UR1: 'UR 1',
  UR3: 'UR 3',
  DustMonRD100: 'DustMon RD 100',
  Inspect_Vision_System: 'Inspect Vision System',
  Turbiscan_AGS: 'Turbiscan AGS',
  Turbiscan_DNS: 'Turbiscan DNS',
  Turbiscan_Lab_1: 'Turbiscan Lab 1',
  Turbiscan_Lab_3: 'Turbiscan Lab 3',
  Turbiscan_Tower: 'Turbiscan Tower',
  Turbiscan_Tower_V1: 'Turbiscan Tower V1',
  Turbiscan_Trilab: 'Turbiscan Trilab',
  Qness_10_M: 'Qness 10 M',
  Qness_10_A: 'Qness 10 A',
  Qness_10_APLUS: 'Qness 10 A+',
  Qness_10_CHD_Master: 'Qness 10 CHD Master',
  Qness_10_CHD_MasterPLUS: 'Qness 10 CHD Master+',
  Qness_30_M: 'Qness 30 M',
  Qness_30_A: 'Qness 30 A',
  Qness_30_APLUS: 'Qness 30 A+',
  Qness_30_CHD_Master: 'Qness 30 CHD Master',
  Qness_30_CHD_MasterPLUS: 'Qness 30 CHD Master+',
  Qness_60_M: 'Qness 60 M',
  Qness_60_A: 'Qness 60 A',
  Qness_60_APLUS: 'Qness 60 A+',
  Qness_60_CHD_Master: 'Qness 60 CHD Master',
  Qness_60_CHD_MasterPLUS: 'Qness 60 CHD Master+',
  Qness_60_M_EVO: 'Qness 60 M EVO',
  Qness_60_A_EVO: 'Qness 60 A EVO',
  Qness_60_APLUS_EVO: 'Qness 60 A+ EVO',
  Qness_150_R: 'Qness 150 R',
  Qness_150_M: 'Qness 150 M',
  Qness_150_A: 'Qness 150 A',
  Qness_150_APLUS: 'Qness 150 A+',
  Qness_150_CS: 'Qness 150 CS',
  Qness_150_CSA: 'Qness 150 CSA',
  Qness_150_CSAPLUS: 'Qness 150 CSA+',
  Qness_200_CS: 'Qness 200 CS',
  Qness_200_CSA: 'Qness 200 CSA',
  Qness_200_CSAPLUS: 'Qness 200 CSA+',
  Qness_250_M: 'Qness 250 M',
  Qness_250_MS: 'Qness 250 MS',
  Qness_250_C: 'Qness 250 C',
  Qness_250_CS: 'Qness 250 CS',
  Qness_250_E: 'Qness 250 E',
  Qness_250_A: 'Qness 250 A',
  Qness_250_APLUS: 'Qness 250 A+',
  Qness_250_CA: 'Qness 250 CA',
  Qness_250_CAPLUS: 'Qness 250 CA+',
  Qness_250_Base: 'Qness 250 Base',
  Qness_750_M: 'Qness 750 M',
  Qness_750_MS: 'Qness 750 MS',
  Qness_750_C: 'Qness 750 C',
  Qness_750_CS: 'Qness 750 CS',
  Qness_750_E: 'Qness 750 E',
  Qness_750_A: 'Qness 750 A',
  Qness_750_APLUS: 'Qness 750 A+',
  Qness_750_CA: 'Qness 750 CA',
  Qness_750_CAPLUS: 'Qness 750 CA+',
  Qness_750_Base: 'Qness 750 Base',
  Qness_3000_M: 'Qness 3000 M',
  Qness_3000_MS: 'Qness 3000 MS',
  Qness_3000_C: 'Qness 3000 C',
  Qness_3000_CS: 'Qness 3000 CS',
  Qness_3000_E: 'Qness 3000 E',
  Qness_3000_A: 'Qness 3000 A',
  Qness_3000_APLUS: 'Qness 3000 A+',
  Qness_3000_CA: 'Qness 3000 CA',
  Qness_3000_CAPLUS: 'Qness 3000 CA+',
  Qness_3000_Base: 'Qness 3000 Base',
  Qness_250_M_EVO: 'Qness 250 M EVO',
  Qness_250_C_EVO: 'Qness 250 C EVO',
  Qness_250_CS_EVO: 'Qness 250 CS EVO',
  Qness_250_E_EVO: 'Qness 250 E EVO',
  Qness_250_A_EVO: 'Qness 250 A EVO',
  Qness_250_APLUS_EVO: 'Qness 250 A+ EVO',
  Qness_250_CA_EVO: 'Qness 250 CA EVO',
  Qness_250_CAPLUS_EVO: 'Qness 250 CA+ EVO',
  Qness_250_Base_EVO: 'Qness 250 Base EVO',
  Qness_750_M_EVO: 'Qness 750 M EVO',
  Qness_750_C_EVO: 'Qness 750 C EVO',
  Qness_750_CS_EVO: 'Qness 750 CS EVO',
  Qness_750_E_EVO: 'Qness 750 E EVO',
  Qness_750_A_EVO: 'Qness 750 A EVO',
  Qness_750_APLUS_EVO: 'Qness 750 A+ EVO',
  Qness_750_CA_EVO: 'Qness 750 CA EVO',
  Qness_750_CAPLUS_EVO: 'Qness 750 CA+ EVO',
  Qness_750_Base_EVO: 'Qness 750 Base EVO',
  Qness_3000_M_EVO: 'Qness 3000 M EVO',
  Qness_3000_C_EVO: 'Qness 3000 C EVO',
  Qness_3000_CS_EVO: 'Qness 3000 CS EVO',
  Qness_3000_E_EVO: 'Qness 3000 E EVO',
  Qness_3000_A_EVO: 'Qness 3000 A EVO',
  Qness_3000_APLUS_EVO: 'Qness 3000 A+ EVO',
  Qness_3000_CA_EVO: 'Qness 3000 CA EVO',
  Qness_3000_CAPLUS_EVO: 'Qness 3000 CA+ EVO',
  Qness_3000_Base_EVO: 'Qness 3000 Base EVO',
  QMobile: 'QMobile',
  Qeye_800: 'Qeye 800',
  Standard_Microscope: 'Standard Microscope',
  Standard_Analog_Hardness_Tester: 'Standard Analog Hardness Tester',
  QMobile_EVO: 'QMoblie EVO',
  Qness_10_APLUS_2: 'Qness 10 A+',
  Qness_10_M_2: 'Qness 10 M',
  Qness_60_APLUS_EVO_2: 'Qness 60 A+ EVO',
  Qness_1000_DCM_C_EVO: 'Qness 1000 DCM C EVO',
  Qness_1000_DCM_CS_EVO: 'Qness 1000 DCM CS EVO',
  Qness_1000_DCM_E_EVO: 'Qness 1000 DCM E EVO',
  Qness_1000_DCM_M_EVO: 'Qness 1000 DCM M EVO',

  TF1_11_32_150_WITH_CC_T1: 'TF1 11/32/150 with CC-T1',
};

@Pipe({
  name: 'rebrandDeviceModel',
})
export class RebrandDeviceModelPipe implements PipeTransform {
  transform(modelId?: string): string {
    if (!modelId) {
      return '';
    }

    return deviceModelDict[modelId] ? deviceModelDict[modelId] : modelId;
  }
}
