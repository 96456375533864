import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  FilterCriteria,
} from '../../../../../product-information/product-information-shared/model/product-information-filter-criteria.model';
import {
  ProductInformationFile,
  ProductInformationFileType,
} from '../../../../../product-information/product-information-shared/model/product-information-file.model';
import { Subscriptions } from '../../../../../shared/util/Subscriptions';
import { faComputer, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { ProductInformationService } from '../../../../../product-information/service/product-information.service';
import { Action } from '../../../../../shared/component/card-header/card-header';
import { ButtonType, LongRunningTask } from '../../../../../shared/component/button/button.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-quick-file-search-listing',
  templateUrl: './quick-file-search-listing.component.html',
  styleUrl: './quick-file-search-listing.component.scss'
})
export class QuickFileSearchListingComponent implements OnDestroy, OnChanges {

  @Input() filterCriteria?: FilterCriteria
  @Input() files?: ProductInformationFile[];
  @Input() fileTypes?: ProductInformationFileType[];
  @Input() isFilesLoading: boolean = false;

  filteredFiles: ProductInformationFile[] = [];
  subscriptions: Subscriptions = new Subscriptions();

  downloadIcon = faFileArrowDown;
  softwareIcon = faComputer;

  locale$?: Observable<string>;
  productInformationFileType = ProductInformationFileType;

  constructor(private productFilesService: ProductInformationService, private translocoService: TranslocoService) {
    this.locale$ = translocoService.langChanges$;
  }

  ngOnChanges(): void {
    this.filteredFiles = this.files || [];
  }

  createDownloadFileAction(fileId: string): Action {
    return new Action({
      title: this.translocoService.translate('download'),
      handler: async (): Promise<void> => {
        return new Promise(resolve => {
          this.downloadFile(fileId);
          resolve();
        });
      },
      isEnabled: () => true,
      type: ButtonType.VENDOR,
    });
  }

  filesByFileType(files: ProductInformationFile[], type: ProductInformationFileType): ProductInformationFile[] {
    return files.filter((file: ProductInformationFile) => this.fileIsOfType(file, type));
  }

  private fileIsOfType(file: ProductInformationFile, type: ProductInformationFileType) {
    return file.type === type;
  }

  downloadFile(fileId: string) {
    this.productFilesService
      .getUri(fileId)
      .pipe(take(1))
      .subscribe(uri => window.open(uri, '_blank'));
  }

  noopHandler: LongRunningTask = async () => {};

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
