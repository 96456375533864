<ng-container *transloco="let t">
  <div class="modal-body modal-dialog-centered" role="document">
    <div class="modal-content" data-cy="modal-content">
      <div
        class="modal-header modal-info-zone border-0 px-5"
        [class.background-warning]="type === 'WARNING'"
        [class.background-error]="type === 'ERROR'"
        [class.background-success]="type === 'SUCCESS'">
        @if (!title) {
          <ng-container className="text-left">
            <app-svg-icon class="iot-logo mt-2" name="icon-iot-logo" color="{{ type ? '#333' : '#fff' }}"> </app-svg-icon>
          </ng-container>
        } @else {
          <div class="d-flex justify-content-between align-items-center info-group">
            <div class="info-heading" [class.text-warning-modal]="type === 'WARNING'">
              <h5 class="modal-title border-0 w-100" data-cy="modal-title">{{ title }}</h5>
              <p class="subheading">{{ descriptiveSubtitle }}</p>
            </div>
            <app-svg-icon class="iot-logo mt-2" name="icon-iot-logo" color="{{ type === 'WARNING' ? '#333' : '#fff' }}">
            </app-svg-icon>
          </div>
          <button
            type="button"
            class="close flex-shrink-1"
            data-dismiss="modal"
            aria-label="Close"
            (click)="close()"
            [class.text-warning-modal]="type === 'WARNING'">
            <span aria-hidden="true">&times;</span>
          </button>
          @if (steps.length > 1) {
            <div class="stepper d-flex w-100 justify-content-center">
              @for (step of steps; track step; let idx = $index) {
                <ng-container class="steps">
                  <div class="steps-wrapper">
                    <app-svg-icon
                      class="step"
                      name="icon-hexagon"
                      [class.done]="currentPage >= idx"
                      [class.step-success]="currentPage === steps.length - 1">
                    </app-svg-icon>
                    <p class="step-label" [ngClass]="{ 'pl-1': idx === 0, 'pl-0': idx === steps.length - 1 }">
                      {{ steps.length - 1 === idx ? '&#10003;' : idx + 1 }}
                    </p>
                  </div>
                </ng-container>
              }
            </div>
          }
          @if (subheadingTemplate) {
            <div class="subheading-template">
              <ng-container *ngTemplateOutlet="subheadingTemplate"></ng-container>
            </div>
          }
        }
      </div>
      <div class="modal-body mt-10 border-0">
        <ng-container *ngTemplateOutlet="currentStepTemplate ? currentStepTemplate : defaultContentTemplate">
        </ng-container>
        <ng-template #defaultContentTemplate>
          <div>{{ t('no-content') }}</div>
        </ng-template>
      </div>
      <div class="modal-footer border-0 mt-10">
        <div class="w-100 text-right">
          <div class="cta-group mt-1">
            @for (action of currentStep?.actions; track action) {
              <app-button
                buttonClass="{{ action.type?.valueOf() }} ml-1"
                iconClass="{{ action.icon }}"
                title="{{ action.title }}"
                [ngClass]="currentStep.actions.length > 1 ? 'align' : 'text-right'"
                [handler]="action.handler ? action.handler : noopHandler"
                [disabled]="action.isEnabled && !action.isEnabled()"
                [isWorkInProgress]="action.isWorking && action.isWorking()">
                <span>{{ action.title }}</span>
              </app-button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
