<div class="card h-100" *transloco="let t">
  <div class="card-header">
    <i class="icon icon-backups"></i>
    <h5 class="card-title" data-cy="cardTitle">
      {{ t('product-information-files') }}
    </h5>
    @if (isFilesLoading) {
      <span class="spinner-border spinner-border-sm ml-3" role="status" aria-hidden="true"></span>
    }
  </div>

  <div class="card-body pt-0">
    @if (!isLoading) {
      <div class="row">
        <div class="col-12 mb-3">
          <app-quick-file-search-filter
            [files]="files"
            (nameQueryChanges)="nameQueryChanged($event)">
          </app-quick-file-search-filter>
        </div>
        <div class="col-12 vertically-scrollable-content">
          <app-quick-file-search-listing
            [files]="files"
            [fileTypes]="types"
            [filterCriteria]="filterCriteria"
            [isFilesLoading]="isFilesLoading">
          </app-quick-file-search-listing>
          @if (canLoadMore && filterCriteria.nameFilter?.length > 0 && files.length >= 10) {
            <app-button
              [title]="t('load-more')"
              [buttonClass]="'btn-outline-vendor'"
              [isWorkInProgress]="isFilesLoading"
              [handler]="loadMore.bind(this)"
            ></app-button>
          }
        </div>
      </div>
    } @else {
      <app-loading-indicator></app-loading-indicator>
    }
  </div>
</div>
