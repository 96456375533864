<ng-container *transloco="let t">
  <div class="item" tabindex="0">
    @if (availableNotificationSummary?.count > 0) {
      <app-device-notification-badge
        class="notification-badge"
        [level]="availableNotificationSummary.maximumLevel"
        [count]="availableNotificationSummary.count">
      </app-device-notification-badge>
    }
    @if (deviceActions.length > 0) {
      <div
        class="close-icon"
        data-cy="actionMenuButton"
        style="position: absolute; top: 0; right: 0">
        <app-dropdown buttonType="btn-link action-menu-btn" [actions]="deviceActions" (click)="ignoreClick($event)">
        </app-dropdown>
      </div>
    }
    <div class="item-container" data-cy="device">
      <div>
        <img
          class="device-image"
          width="80"
          height="80"
          srcset="{{ device | deviceImage: ImageSize.Thumbnail}} 240w, {{device | deviceImage: ImageSize.Full }} 1500w"
          alt="Device" />
      </div>
      <div>
        <p class="header">
          @if (device.isOffline) {
            <span class="badge badge-warning mr-1">
              <i class="fa fa-info-circle mr-1"></i>{{ t('offline') }}
            </span>
          } @else if (areFirmwareUpdatesAvailable) {
            <span class="badge badge-success mr-1">
              <i class="fa fa-arrow-circle-up mr-1"></i>{{ t('update-available-short') }}
            </span>
          }
          {{ device?.name }}
          @if (isPinned) {
            <fa-icon [icon]="thumbtackIcon" class="fa fa-fw pinned-icon"></fa-icon>
          }
        </p>
        <p class="info">
          <i class="fa fa-map-marker fa-fw"></i>
          {{ device?.virtualLaboratory.name }}
        </p>
        <p class="info">
          <i class="fa fa-info-circle fa-fw"></i>
          {{ device?.model | deviceModelFormatter }}
        </p>
        <p>
          <fa-icon [icon]="barcodeIcon" class="fa fa-fw"></fa-icon>
          {{ device?.serialNumber }}
        </p>
      </div>
      <div class="connection-status">
        <app-device-connection-status [flags]="flags"></app-device-connection-status>
      </div>
      @if (progressInformation) {
        <div class="operational-status">
          <div class="progress-info">
            <div class="progress-info-message">
              <span placement="top" ngbTooltip="{{ progressInformation?.message || '' }}">{{
                progressInformation?.message || ''
              }}</span>
            </div>
            <div class="progress-info-text text-muted">
              <app-operational-status-progress-info-text [progressInformation]="progressInformation">
              </app-operational-status-progress-info-text>
            </div>
          </div>
          <div class="progress-bar-container">
            <div class="progress-info-text-mobile text-muted">
              <app-operational-status-progress-info-text [progressInformation]="progressInformation">
              </app-operational-status-progress-info-text>
            </div>
            <app-device-operational-status-progress-bar [height]="'15px'" [progressInformation]="progressInformation">
            </app-device-operational-status-progress-bar>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>
