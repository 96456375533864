<ng-container *transloco="let t">
  @for (type of fileTypes; track type; let i = $index) {
    @if (filesByFileType(filteredFiles, type); as filteredFilesByType) {
      @if (filteredFilesByType.length > 0) {
        <div class="d-flex justify-content-start align-items-center">
          <h5 class="mr-3 type-title">{{ t('product-information-files.file-type.enum.' + type) }}</h5>
        </div>

        <div class="mb-10">
          @for (file of filteredFilesByType; track file.id) {
            @if (file) {
              <ng-container *ngTemplateOutlet="filteredFileByType; context: { file: file }"></ng-container>
            }
          }
        </div>
      }
    }
  }

  @if (files?.length === 0 && !isFilesLoading) {
    <div class="card-body h-100 d-flex justify-content-center align-items-center">
      <p class="text-muted">{{ t('product-information-files.no-files') }}</p>
    </div>
  }
</ng-container>

<ng-template #filteredFileByType let-file="file">
  <div class="card flex-row justify-content-between align-items-center px-3 mt-5">
    <div class="d-flex justify-content-start align-items-center">
      @if (file.type == productInformationFileType.Software) {
        <fa-icon class="pr-2" [icon]="softwareIcon"></fa-icon>
      } @else {
        <i class="fa fa-file-pdf-o pr-2" aria-hidden="true"></i>
      }
      <h5 class="py-5 item-title m-0">{{ file?.name || '' }}</h5>
    </div>
    @if (createDownloadFileAction(file.id); as action) {
      <app-button
        buttonClass="{{ action.type?.valueOf() }} ml-1"
        [icon]="downloadIcon"
        title=""
        [handler]="action.handler ? action.handler : noopHandler"
        [disabled]="action.isEnabled && !action.isEnabled()"
        [isWorkInProgress]="action.isWorking && action.isWorking()">
      </app-button>
    }
  </div>
</ng-template>
