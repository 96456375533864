import {
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  signal
} from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, combineLatestWith, Observable, switchMap, take } from 'rxjs';
import { CoreNavigationService } from 'src/app/core/service/core-navigation.service';
import { ConfirmDialogType } from 'src/app/shared/component/confirm-dialog/model/confirmation-dialog-types';
import { ButtonType } from '../../../../shared/component/button/button.component';
import { Action } from '../../../../shared/component/card-header/card-header';
import { Step } from '../../../../shared/component/dialog/dialog.component';
import { Option } from '../../../../shared/component/multiselect/multiselect.component';
import { ErrorType, MessageInfo } from '../../../../shared/model/errors';
import { hasInvalidUserInput } from '../../../../shared/model/forms';
import { Subscriptions } from '../../../../shared/util/Subscriptions';
import { Device, DeviceModel, DeviceRegistrationProperties, DeviceVendor } from '../../model/device.model';
import {
  DeviceRegistration,
  DeviceRegistrationPhase,
  DeviceRegistrationStateService
} from '../../service/device-registration-state.service';
import { DeviceVendorsService } from '../../service/device-vendors.service';
import {
  ProductInformationService,
  SoftwareOption
} from 'src/app/product-information/service/product-information.service';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { DeviceModelApiService } from '../../../../core/api/device-model-api.service';
import { filter, tap } from 'rxjs/operators';
import { DeviceModelInfo } from '../../../../core/model/device-model.model';
import { SharedDeviceApiService } from '../../api/shared-device-api.service';

export interface VirtualLaboratoryPreviewReference {
  id: string;
  name: string;
}

export interface DeviceRegistrationErrorResponse {
  errorCode?: DeviceRegistrationErrors,
  message: string,
}

export enum DeviceRegistrationErrors {
  DUPLICATE_SERIAL_NUMBER = 'DUPLICATE_SERIAL_NUMBER',
  INVALID_SERIAL_NUMBER = 'INVALID_SERIAL_NUMBER'
}

export enum DeviceRegistrationErrorsMessages {
  DUPLICATE_SERIAL_NUMBER = 'device.registration-dialog.result-message.device-exists-error',
  INVALID_SERIAL_NUMBER = 'validation.error.invalid-serial-number'
}

@Component({
  templateUrl: './device-registration-dialog.component.html',
  styleUrls: ['./device-registration-dialog.component.scss'],
})
export class PrepareDeviceRegistrationDialogComponent implements OnInit, OnDestroy {
  @Input() virtualLaboratories: VirtualLaboratoryPreviewReference[] = [];
  @Input() type: ConfirmDialogType = ConfirmDialogType.Info;

  isWorking: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  isLoading = true;
  registration$: Observable<DeviceRegistration | undefined>;

  registeredDevice: Device | undefined = undefined;

  virtualLaboratorySelectOptions: Option[] = [];
  vendorSelectOptions: Option[] = [];
  deviceModelInfoList: DeviceModelInfo[] = [];
  modelSelectOptions: Option[] = [];

  isModelsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isOfflineRegistrationLoading = false;
  offlineRegistrationError: string | undefined;

  currentPageIndex: number = 0;

  token?: string;
  softwareVersion = signal('');
  softwareOptions: Signal<SoftwareOption[] | undefined> = signal(undefined);
  softwareDownloadAction: Action[] = [];

  downloadIcon = faFileDownload;

  qatmRegex = /^[qQ]\d{4}\/(0[1-9]|1[0-2])\/$/;
  eltraRegex = /^\d{4,5}\d[01-54]/;
  microtracRegex: Record<string, RegExp> = {
    turbiscanLab: /^\d{4}-TL-\d{4}$/,
    turbiscanTrilab: /^\d{4}-TTRI-\d{4}$/,
    turbiscanTower: /^\d{4}-TTOW-\d{4}$/,
    turbiscanAgs: /^\d{4}-TAGS-\d{4}$/,
    turbiscanDns: /^\d{4}-TDNS-\d{4}$/
  }

  form = this.fb.group({
    virtualLaboratory: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.required],
      updateOn: 'change',
    }),
    vendor: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.required],
      updateOn: 'change',
    }),
    model: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.required],
      updateOn: 'change',
    }),
    serialNumber: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(8)],
      updateOn: 'change',
    }),
    isOffline: this.fb.control(false, {
      updateOn: 'change',
    })
  });

  private subscriptions: Subscriptions = new Subscriptions();

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private translocoService: TranslocoService,
    private deviceVendorsService: DeviceVendorsService,
    private changeDetection: ChangeDetectorRef,
    private navigationService: CoreNavigationService,
    private deviceRegistrationStateService: DeviceRegistrationStateService,
    private productFilesService: ProductInformationService,
    private deviceModelApiService: DeviceModelApiService,
    private sharedDeviceApiService: SharedDeviceApiService,
    private injector: Injector
  ) {
    this.registration$ = deviceRegistrationStateService.registration$;
  }

  ngOnInit(): void {
    this.model.disable();
    this.vendor.valueChanges.subscribe(vendor => {
      if (vendor === DeviceVendor.Microtrac) {
        this.isOffline.setValue(true);
      }
    })
    this.vendor.valueChanges.pipe(combineLatestWith(this.isOffline.valueChanges, this.isModelsLoading)).subscribe(([ vendor, isOffline, isLoading ]) => {
      if (!isOffline) {
        this.model.setValue('');
      }
      if (isOffline && !isLoading) {
        if (vendor?.length > 0) {
          this.model.enable();
        } else {
          this.model.disable();
        }
      } else {
        this.model.disable();
      }
    });

    this.vendor.setValue(null);
    this.isOffline.setValue(null);

    this.virtualLaboratorySelectOptions = this.virtualLaboratories.map(virtualLaboratory => {
      return {
        value: virtualLaboratory.id,
        label: virtualLaboratory.name,
      };
    });

    if (this.virtualLaboratorySelectOptions.length === 1) {
      const control = this.form.controls['virtualLaboratory'];
      control.setValue(this.virtualLaboratorySelectOptions[0].value);
      control.disable();
    }

    this.vendorSelectOptions = this.deviceVendorsService.asOptions();

    this.vendor.valueChanges.pipe(
      tap(() => {
        this.modelSelectOptions = [];
        this.model.setValue(null);
      }),
      filter(vendor => vendor?.length > 0),
      tap(() => {
        this.isModelsLoading.next(true);
        this.model.markAsUntouched();
        this.model.setErrors(null);
      }),
      switchMap(vendor => this.deviceModelApiService.getAllByVendor(vendor)),
    ).subscribe({
      next: models => {
        this.isModelsLoading.next(false);
        this.deviceModelInfoList = models;
        this.modelSelectOptions = this.modelsToOptions(models);
      },
      error: () => this.isModelsLoading.next(false)
    });

    this.form.valueChanges.subscribe(() => this.offlineRegistrationError = undefined);

    this.subscriptions.put(
      this.deviceRegistrationStateService.registration$.subscribe(registration => {
        if (!registration) {
          return;
        }
        if (registration.phase != DeviceRegistrationPhase.WaitingForDevice) {
          this.currentPageIndex = 3;

          this.type = ConfirmDialogType.Success;
          this.registeredDevice = registration.device;
          this.changeDetection.detectChanges();
          return;
        }

        this.token = registration.token?.token;
        this.currentPageIndex = 2;
        this.changeDetection.detectChanges();
      })
    );
    this.subscriptions.put(this.deviceRegistrationStateService.listenForDevice().subscribe());
    this.getRegistrationSoftware();
  }

  get steps(): Step[] {
    return [
      {
        actions: [
          new Action({
            title: this.translocoService.translate('cancel'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.activeModal.close(false);
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.LINK,
          }),
          new Action({
            title: this.translocoService.translate('device.registration-dialog.action.continue'),
            handler: async (): Promise<void> => {
              if (this.isOffline.value) {
                return new Promise((resolve, reject) => {
                  this.isOfflineRegistrationLoading = true;
                  const deviceProperties: DeviceRegistrationProperties = {
                    vendor: this.vendor.value,
                    model: this.generateDeviceModel(this.deviceModelInfoList.find(model => model.id === this.model.value)!),
                    serialNumber: this.serialNumber.value,
                    virtualLaboratoryId: this.virtualLaboratory.value,
                  }
                  this.sharedDeviceApiService.prepareDeviceOfflineRegistration(deviceProperties).subscribe({
                    error: ({ errorCode }: DeviceRegistrationErrorResponse) => {
                      this.offlineRegistrationError = errorCode ? DeviceRegistrationErrorsMessages[errorCode] : 'device.registration-dialog.result-message.default-error';
                      this.isOfflineRegistrationLoading = false;
                      reject();
                    },
                    complete: () => {
                      this.isOfflineRegistrationLoading = false;
                      this.currentPageIndex = 3;
                      resolve();
                    }
                  });
                })
              } else {
                if (!this.selectRegex()) {
                  this.serialNumber.setErrors({ invalidSerialNumber: true });
                  return;
                }
                return new Promise(resolve => {
                  this.getRegistrationSoftware();
                  this.form.get('vendor')?.value === DeviceVendor.QATM
                    ? this.softwareVersion.set('3.4.x.x')
                    : this.softwareVersion.set('1.7.3.1');
                  this.currentPageIndex = 1;
                  resolve();
                });
              }
            },
            isWorking: (): boolean => this.isOfflineRegistrationLoading,
            isEnabled: (): boolean => {
              const isChanging = this.isWorking.getValue();
              return !this.form.invalid && !isChanging;
            },
            type: ButtonType.PRIMARY,
          }),
        ],
      },
      {
        actions: [
          new Action({
            title: this.translocoService.translate('cancel'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.activeModal.close(false);
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.LINK,
          }),
          new Action({
            title: this.translocoService.translate('device.registration-dialog.action.confirm-software'),
            handler: async (): Promise<void> => {
              return this.doRegisterDevice();
            },
            isEnabled: (): boolean => true,
            isWorking: (): boolean => {
              return this.isWorking.getValue();
            },
            type: ButtonType.PRIMARY,
          }),
        ],
      },
      {
        actions: [
          new Action({
            title: this.translocoService.translate('cancel'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.deviceRegistrationStateService.cancelRegistration();
                this.activeModal.close(false);
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.PRIMARY,
          }),
        ],
      },
      {
        actions: [
          new Action({
            title: this.translocoService.translate('close'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.activeModal.close(false);
                this.deviceRegistrationStateService.cancelRegistration();
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.LINK,
          }),
          new Action({
            title: this.translocoService.translate('device.registration-dialog.action.add-another-device'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.currentPageIndex = 0;
                resolve();
              });
            },
            isEnabled: () => true,
            type: ButtonType.PRIMARY_OUTLINE,
          }),
          new Action({
            title: this.translocoService.translate('device.registration-dialog.action.open-device-details'),
            handler: async (): Promise<void> => {
              return new Promise(_resolve => {
                this.activeModal.close(false);
                this.deviceRegistrationStateService.cancelRegistration();
                if (this.registeredDevice) {
                  this.navigationService.navigateToDeviceDetails(
                    this.registeredDevice.vendor,
                    this.registeredDevice.type,
                    this.registeredDevice.model.baseModelIdentification?.id ||
                      this.registeredDevice.model.identification.id,
                    this.registeredDevice.model.baseModelIdentification?.version ||
                      this.registeredDevice.model.identification.version ||
                      'latest',
                    this.registeredDevice.id
                  );
                }
              });
            },
            isEnabled: () => true,
            type: ButtonType.MODAL_SUCCESS,
          }),
        ],
      },
    ];
  }

  getRegistrationSoftware() {
    const serialNumber = this.form.get('serialNumber')?.value;
    if (!serialNumber) return;

    const capitalizedSerialNumber = serialNumber.charAt(0).toUpperCase() + serialNumber.slice(1);
    const body = { vendor: this.form.get('vendor')?.value, serialNumber: capitalizedSerialNumber };

    this.subscriptions.put(
      this.productFilesService.getRegistrationSoftware(body).subscribe(() => {
        this.isLoading = false;
      })
    );
    this.softwareOptions = computed(() => {
      return this.productFilesService.softwareOptions();
    });

    effect(
      () => {
        this.softwareDownloadAction =
          this.softwareOptions()?.map(
            option =>
              new Action({
                title: this.translocoService.translate('download'),
                handler: async (): Promise<void> => {
                  return new Promise(resolve => {
                    this.downloadFile(option.id);
                    resolve();
                  });
                },
                isEnabled: () => true,
                type: ButtonType.PRIMARY,
              })
          ) || [];
      },
      { injector: this.injector }
    );
  }

  doRegisterDevice() {
    this.isWorking.next(true);

    this.subscriptions.put(
      this.deviceRegistrationStateService
        .initiateRegistration(
          this.form.controls['virtualLaboratory'].value,
          this.vendor?.value || '',
          this.serialNumber?.value || ''
        )
        .subscribe(() => {
          this.currentPageIndex = 2;
        })
    );
  }

  get virtualLaboratory(): any {
    return this.form.get('virtualLaboratory');
  }

  get isVirtualLaboratoryValid(): boolean {
    return hasInvalidUserInput(this.virtualLaboratory);
  }

  get virtualLaboratoryInfoMessages(): MessageInfo | undefined {
    const errors = this.virtualLaboratory.errors;
    if (!errors) {
      return;
    }
    if (errors?.required) {
      return {
        description: 'error.no-item-selected',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
  }

  get vendor(): AbstractControl {
    return this.form.get('vendor')!;
  }

  get isVendorValid(): boolean {
    return hasInvalidUserInput(this.vendor);
  }

  get vendorInfoMessages(): MessageInfo | undefined {
    const errors = this.vendor.errors;
    if (!errors) {
      return;
    }
    if (errors?.required) {
      return {
        description: 'error.no-item-selected',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
  }

  get model(): AbstractControl {
    return this.form.get('model')!;
  }

  get isModelValid(): boolean {
    return hasInvalidUserInput(this.model);
  }

  get modelInfoMessages(): MessageInfo | undefined {
    if (this.model.untouched) return;
    const errors = this.model.errors;
    if (!errors) {
      return;
    }
    if (errors?.required) {
      return {
        description: 'error.no-item-selected',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
  }

  get serialNumber(): any {
    return this.form.get('serialNumber');
  }

  get isSerialNumberValid(): boolean {
    return hasInvalidUserInput(this.serialNumber) && !this.serialNumber.errors?.invalidSerialNumber;
  }

  get serialNumberInfoMessages(): MessageInfo | undefined {
    const errors = this.serialNumber.errors;
    if (!errors) {
      return;
    }
    if (errors?.required || errors?.minlength) {
      return {
        params: {
          minlength: 8,
        },
        description: 'validation.error.min-length',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
    if (errors?.invalidSerialNumber) {
      return {
        description: 'validation.error.invalid-serial-number',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
  }

  get isOffline(): AbstractControl {
    return this.form.get('isOffline')!;
  }

  selectRegex(): boolean {
    const serialNumber = this.serialNumber.value;
    const vendor: DeviceVendor = this.vendor?.value as DeviceVendor;

    if (vendor === DeviceVendor.Microtrac && this.isOffline.value) {
      const identifier = this.deviceModelInfoList.find(model => model.id === this.model.value)?.identifier;
      if (!identifier) return false;
      switch (identifier) {
        case 'Turbiscan_Lab':
          return this.microtracRegex.turbiscanLab.test(serialNumber);
        case 'Turbiscan_Trilab':
          return this.microtracRegex.turbiscanTrilab.test(serialNumber);
        case 'Turbiscan_Tower':
          return this.microtracRegex.turbiscanTower.test(serialNumber);
        case 'Turbiscan_AGS':
          return this.microtracRegex.turbiscanAgs.test(serialNumber);
        case 'Turbiscan_DNS':
          return this.microtracRegex.turbiscanDns.test(serialNumber);
        default:
          return true;
      }
    }
    switch (vendor) {
      case DeviceVendor.QATM:
        return this.validateSerialNumber(serialNumber, this.qatmRegex);
      case DeviceVendor.Eltra:
        return this.validateSerialNumber(serialNumber, this.eltraRegex);
      default:
        return true;
    }
  }

  validateSerialNumber(serialNumber: string, regex: RegExp): boolean {
    const serialNumberSplit = serialNumber.slice(0, -2);
    const year = parseInt(serialNumber.slice(-2), 10);
    const currentYear = new Date().getFullYear() % 100;
    return regex.test(serialNumberSplit) && year <= currentYear;
  }

  downloadFile(fileId: string) {
    this.productFilesService
      .getUri(fileId)
      .pipe(take(1))
      .subscribe(uri => window.open(uri, '_blank'));
  }

  private modelsToOptions(models: DeviceModelInfo[]): Option[] {
    return models.map(model => ({
      value: model.id,
      label: model.name
    }));
  }

  private generateDeviceModel(info: DeviceModelInfo): DeviceModel {
    return {
      identification: {
        id: info.identifier,
        version: info.version,
      }
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
