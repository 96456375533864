import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  ProductInformationFile,
  ProductInformationFileType,
} from '../../../../../product-information/product-information-shared/model/product-information-file.model';
import { Option } from '../../../../../shared/component/multiselect/multiselect.component';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-quick-file-search-filter',
  templateUrl: './quick-file-search-filter.component.html',
})
export class QuickFileSearchFilterComponent implements OnInit, OnChanges {

  @Input() files: ProductInformationFile[] | undefined;

  filterableFileTypes: Option[] = [];
  searchQuery: FormControl = new FormControl('');

  selectedFileLanguages: string[] = [];

  @Output() nameQueryChanges = new EventEmitter<string>();

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.selectedFileLanguages = [this.translocoService.getActiveLang()];

    this.searchQuery.valueChanges.subscribe(() => {
      this.nameQueryChanges.emit(this.searchQuery.value);
    })
  }

  ngOnChanges() {
    // Eliminate SoftwareEltra from the filter options
    this.filterableFileTypes = Object.entries(ProductInformationFileType)
      .filter(([key, value]) => value !== ProductInformationFileType.SoftwareEltra)
      .map(([key, value]) => {
        return {
          value: key,
          label: this.translocoService.translate(`product-information-files.file-type.enum.${key}`),
        } as Option;
      });
  }
}
