import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { AvailableDeviceModelFirmwareUpdate } from 'src/app/device-firmware/model/device-firmware-available-update.model';
import {
  createDeviceModelReference,
  DeviceNotificationLevel,
  DeviceSummary,
  DeviceVendor,
} from 'src/app/device/device-shared/model/device.model';
import {
  ConnectionStatusComponentFlags,
  createFlags,
  DeviceStatusType,
} from '../../device-shared/component/connection-status/connection-status.component';
import {
  ProgressInformation,
  ProgressInformationCalculator,
} from '../../device-shared/component/operational-status/progress-information.calculator';
import { faBarcode, faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { Action } from 'src/app/shared/component/card-header/card-header';
import { ImageSize } from '../../../shared/pipe/device-image.pipe';

interface AvailableNotificationSummary {
  maximumLevel: DeviceNotificationLevel;
  count: number;
}

function mapDeviceSummaryInfo(device: DeviceSummary): DeviceStatusType {
  return {
    connectionStatusType: device?.status.connection.type,
    operationalStatusType: device?.status.operational?.type,
  };
}

@Component({
  selector: 'app-device-list-item',
  templateUrl: './device-list-item.component.html',
  styleUrls: ['./device-list-item.component.scss'],
})
export class DeviceListItemComponent implements OnInit {
  barcodeIcon = faBarcode;
  thumbtackIcon = faThumbTack;

  @Input() device?: DeviceSummary;
  @Input() availableFirmwareUpdates?: AvailableDeviceModelFirmwareUpdate[];
  @Input() deviceActions?: Action[] = [];
  @Input() isPinned: boolean = false;

  availableNotificationSummary?: AvailableNotificationSummary;

  progressInformation?: ProgressInformation;

  flags!: ConnectionStatusComponentFlags | undefined;
  deviceVendor = DeviceVendor;
  
  ngOnInit(): void {
    this.availableNotificationSummary = DeviceListItemComponent.createAvailableNotificationSummary(this.device);
    this.progressInformation = !!this.device?.status
      ? ProgressInformationCalculator.calculateProgress(this.device?.status)
      : undefined;
    if (this.device) {
      this.flags = createFlags(mapDeviceSummaryInfo(this.device));
    }
  }

  get areFirmwareUpdatesAvailable(): boolean {
    return !!(this.availableFirmwareUpdates || []).find(update => {
      return (
        this.device &&
        _.isEqual(createDeviceModelReference(this.device), update.deviceModel) &&
        !!this.device.firmwares.find(firmware => firmware.key === update.key && firmware.version === update.version) &&
        this.device.vendor === this.deviceVendor.QATM
      );
    });
  }

  private static createAvailableNotificationSummary(device?: DeviceSummary): AvailableNotificationSummary | undefined {
    if (!device) {
      return undefined;
    }
    let maximumNotificationLevel = null;
    let count = null;

    if (device.notificationSummary?.available.critical > 0) {
      maximumNotificationLevel = DeviceNotificationLevel.Critical;
      count = device.notificationSummary.available.critical;
    } else if (device.notificationSummary?.available.error > 0) {
      maximumNotificationLevel = DeviceNotificationLevel.Error;
      count = device.notificationSummary.available.error;
    } else if (device.notificationSummary?.available.warning > 0) {
      maximumNotificationLevel = DeviceNotificationLevel.Warning;
      count = device.notificationSummary.available.warning;
    } else if (device.notificationSummary?.available.info > 0) {
      maximumNotificationLevel = DeviceNotificationLevel.Info;
      count = device.notificationSummary.available.info;
    }

    if (maximumNotificationLevel != null && count != null) {
      return { maximumLevel: maximumNotificationLevel, count };
    } else {
      return undefined;
    }
  }
  ignoreClick(event: Event) {
    event.stopPropagation();
  }
  
  protected readonly ImageSize = ImageSize;
}
