export enum ProductInformationFileType {
  OperatingInstructions = 'OPERATING_INSTRUCTIONS',
  ApplicationNotes = 'APPLICATION_NOTES',
  Software = 'SOFTWARE',
  SoftwareEltra = 'SOFTWARE_ELTRA',
}

export interface ProductInformationFile {
  id: string;
  name: string;
  type: ProductInformationFileType;
  contentType: string;
  contentLength: number;
  languageCode: string;
  modifiedAt: Date;
  description: string;
  isFavorite?: boolean;
}
