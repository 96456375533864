<label *ngIf="label" class="form-label" for="{{ id }}">{{ label }}</label>
<input
  class="form-control pl-2"
  type="text"
  [id]="id"
  [formControl]="formControl"
  [placeholder]="placeholder || ''"
  [class.is-invalid]="formControl?.touched && formControl?.invalid"
/>
<app-input-info-message [show]="formControl?.touched && !!messageInfo" [message]="messageInfo"></app-input-info-message>
