<ng-container>
 <div class="position-relative">
   @if (hasLabel) {
     <label [for]="inputId">{{ label }}</label>
   }
   <select
     [id]="inputId"
     [name]="inputId"
     class="form-control {{ selectClass }}"
     [class.is-invalid]="!isValid"
     (change)="writeValue($any($event.target).value)"
     (blur)="onTouch($event)"
     [disabled]="disabled">
     <option value="">{{ emptyOption | transloco }}</option>
     @for (option of options; track option) {
       <option [value]="option.value" [selected]="isSelectedOption(option)">
         {{ option.label }}
       </option>
     }
   </select>
   @if (!isValid && messageInfo) {
     <app-input-info-message [show]="!isValid" [message]="messageInfo"></app-input-info-message>
   }
   @if (loading) {
     <div class="spinner-border loading"></div>
   }
 </div>
</ng-container>
