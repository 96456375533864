import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl, FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import { MessageInfo } from '../../model/errors';

@Component({
  selector: 'app-input-new',
  templateUrl: './input-new.component.html',
  styleUrls: ['./input-new.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputNewComponent),
    },
  ],
})
export class InputNewComponent implements ControlValueAccessor, OnInit {
  
  @Input() formControl: FormControl | undefined;
  
  @Input() label: string | undefined;
  
  @Input() id: string | undefined;
  
  @Input() placeholder: string | undefined;
  
  @Input() messageInfo: MessageInfo | undefined;
  
  constructor(private injector: Injector) {}
  
  ngOnInit() {
    const ngControl = this.injector.get(NgControl);
  
    if (ngControl instanceof FormControlName) {
      this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this.formControl = (ngControl as FormControlDirective).form as FormControl;
    }
  }
  
  writeValue(value: string): void {
    this.onChange(value);
  }
  
  registerOnChange(fn: (value: string) => any): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  
  onChange (val: string): any {}
  
  onTouched (): any {}
}
